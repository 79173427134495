import { Observable } from 'rxjs';
import { ApiGuiaMassagemService } from './api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginDTO, LoginResponseDTO } from '../interfaces/DTOs/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiGuiaMassagemService {
  constructor(http: HttpClient) {
    super(http);
    this.setEndpoint('auth');
  }

  public login(dados: LoginDTO): Observable<LoginResponseDTO> {
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', dados.username);
    body.set('password', dados.password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<LoginResponseDTO>(
      `${this.url}/token`,
      body.toString(),
      { headers }
    );
  }

  public saveToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  logout(): void {
    localStorage.removeItem('access_token');
  }

  public get isAuthenticated(): boolean {
    return !!this.getToken();
  }
}
